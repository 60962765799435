// Author: Mutasim I.
// License: Opensource under the GNU GPLv3
// Description: this file is part of TMTGPS system
import {
  IonThumbnail,
  IonImg,
  IonContent,
  IonHeader,
  IonPage,
  IonToast,
  IonTitle,
  IonLoading,
  IonToolbar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonItem,
  IonButton,
  IonCard,
  IonList,
  IonSearchbar,
} from '@ionic/react';
import React from 'react';
import '../theme/styles.css';
import VehiclesList from '../components/VehiclesList';
import ReportGenerator from '../components/ReportGenerator';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import {tmtgpsIMG} from '../theme/images.json';
import html2canvas from 'html2canvas';
import { formatDateTime } from '../lib/formatDateTime'
class Reports extends React.Component {
  constructor() {
    super()
    this.state = {
      apiKey: "",
      vehicles: [],
      reportedVehicle: {},
      showSearch: false,
      vehiclesSearch: [],
      reportGeneratorStatus: false,
      reportFromDate: new Date(),
      reportToDate: new Date(),
      reportData: [],
      reportReadyForDownload: false,
      loading: false,
      errorMsg: null
    }
  }

  setReportedVehicle(vehicle) {
    this.setState({
      reportedVehicle: vehicle
    })
  }

  handleVehicleSearch(imei) {
    if (imei) {
      let vehiclesSearch = this.state.vehicles.filter(function (vehicle) { return vehicle.imei === imei })
      this.setState({
        vehiclesSearch: vehiclesSearch
      })
    } else {
      let vehiclesSearch = this.state.vehicles
      this.setState({
        vehiclesSearch: vehiclesSearch,
      })
    }
  }

  async generateReport() {
    try {
    if (this.state.apiKey && this.state.reportedVehicle.imei) {
      this.setState({
        data: []
      })
      let startDate = formatDateTime(this.state.reportFromDate)
      let endDate = formatDateTime(this.state.reportToDate)
      this.setState({
        loading: true
      })

      const res = await fetch(
        `https://api.track.tmtgps.io/api/api.php?api=user&ver=1.0&key=${this.state.apiKey}&cmd=OBJECT_GET_MESSAGES,${this.state.reportedVehicle.imei},${startDate},${endDate}`
      )
      const messages = await res.json();
      if (res.status >= 200 && res.status <= 399 && messages) {
        let data = [];
        let prev = {io86: 0, io25:0}
        messages.sort()
        messages.map((message) => {
            let date = formatDateTime(new Date(message[0]))
            let engineStatus = (message[6].io239 === "0") ? 'Off' : 'On'
            let io86 //HUMIDITY 10804
            if (message[6].io86) {
              prev.io86 = (message[6].io86 * 0.1).toFixed(2)
              io86 = (message[6].io86 * 0.1).toFixed(2)
            } else {
              io86 = prev.io86
            }
            if (message[6].io10804) {
              io86 = Math.floor(Number(message[6].io10804))
            }
            let io25 //TEMP 10800
            if (message[6].io25 && message[6].io25 !== 3000) {
              prev.io25 = message[6].io25
              io25 = message[6].io25
            } else {
              io25 = prev.io25
            }
            if (message[6].io10800 && message[6].io10800 !== 3000) {
              io25 = Math.floor(Number(message[6].io10800) * 0.01)
            }
            data.push([
              date,
              engineStatus,
              io86,
              io25
            ])
            return true
        })
        this.setState({
          reportData: data,
          reportReadyForDownload: true,
          loading: false
        })

        //ARABIC NAME TO IMAGE
        const ii = document.createElement('P');
        ii.id = "vehicleImageName";
        ii.style = "color: black;margin:0px;font-size:15px;"
        ii.innerText = this.state.reportedVehicle.name;
          document.body.appendChild(ii);
        const canvas = await html2canvas(document.querySelector("#vehicleImageName"));
        const vehicleNameImage = canvas.toDataURL('image/png');

        //STARTPDFGEN
        const doc = new jsPDF();
        doc.addImage(tmtgpsIMG, 'PNG', 5 , 10, 40, 10)
        doc.addImage(vehicleNameImage, 'PNG', 140 , 20 , 0 , 0 )
        doc.setFontSize(10)
        doc.setTextColor(255, 0, 0)
        doc.text(140, 17, 'Humidity & Temprature Report')

        doc.setFontSize(10)
        doc.setTextColor(100)
        doc.text(10, 30, "Vehicle: " + this.state.reportedVehicle.imei)

        doc.setFontSize(10)
        doc.setTextColor(100)
        doc.text(10, 35, "Report From: " + startDate + " To: " + endDate)

        doc.autoTable({
          headStyles:{
            valign: 'middle',
            halign : 'center'
          },
          columnStyles: {
            0: { halign: 'center', valign: 'middle'},
            1: { halign: 'center', valign: 'middle'},
            2: { halign: 'center', valign: 'middle'},
            3: { halign: 'center', valign: 'middle'}
          }, // Cells in first column centered and green
          startY: 40,
          head: [['Time', 'Engine State', 'Humidity','Temprature']],
          body: this.state.reportData,
        })
        doc.save(`${this.state.reportedVehicle.imei}-Humidity&Temp.pdf`);
        document.body.removeChild(document.getElementById("vehicleImageName"));

        //ENDPDFGEN
        this.handleReportModal();
      } else {
        throw new Error('Error')
      }
    }
  } catch {
    this.setState({
      loading: false,
      errorMsg: 'Error! check connection, check report fields'
    })
  }
  }

  handleReportModal() {
      let reportGeneratorStatus = !this.state.reportGeneratorStatus
      this.setState({
        reportGeneratorStatus: reportGeneratorStatus,
        reportReadyForDownload: false
      })
  }

  setReportFromDate(date) {
    this.setState({
      reportFromDate: date,
      reportReadyForDownload: false
    })
  }

  setReportToDate(date) {
    this.setState({
      reportToDate: date,
      reportReadyForDownload: false
    })
  }

  setAPIKey(apiKey) {
    this.setState({
      apiKey: apiKey
    })
  }

  async fetchVehicles() {
    try {
      this.setState({
        loading: true
      })
      if (this.state.apiKey) {
        const res = await fetch("https://api.track.tmtgps.io/api/api.php?api=user&ver=1.0&key=" + this.state.apiKey + "&cmd=USER_GET_OBJECTS" );
        const vehicles = await res.json();
        if (res.status >= 200 && res.status <= 399 && vehicles) {
          this.setState({
            vehicles: vehicles,
            showSearch: true,
            vehiclesSearch: vehicles,
            loading: false
          })
          return;
        }
      }
      throw new Error('Error')
    }
    catch {
      this.setState({
        loading: false,
        errorMsg: 'Error Connection Error!'
      })
    }

  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>TMTGPS Reports</IonTitle>
            <IonThumbnail slot="end">
              <IonImg src="assets/icon/icon.png" />
            </IonThumbnail>
          </IonToolbar>
        </IonHeader>
        {(() => {
          if (!this.state.vehicles[0]) {
            return (
              <IonContent fullscreen>
                <IonGrid>
                  <IonRow >
                    <IonCol class="flex-center">
                      <IonCard>
                        <IonItem>
                          <IonLabel position="stacked">Enter API Key</IonLabel>
                          <IonInput autofocus="true" clearInput="true" onIonInput={(event) => this.setAPIKey(event.target.value)} />
                        </IonItem>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol class="flex-center">
                      <IonButton expand="full" color="dark" onClick={() => this.fetchVehicles()}>Go</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>

            )
          }
        })()}
        {(() => {
          if (this.state.showSearch) {
            return (
              <IonContent>
                <IonSearchbar
                  onIonChange={(event) => this.handleVehicleSearch(event.detail.value)}
                />
                <IonList>
                  <VehiclesList
                    vehicles={this.state.vehiclesSearch}
                    handleReportModal={() => { this.handleReportModal() }}
                    setReportedVehicle={(vehicle) => { this.setReportedVehicle(vehicle) }}
                  />
                </IonList>
              </IonContent>
            )
          }
        })()}

        <IonLoading
          isOpen={this.state.loading}
          message={'Loading...'}
        />

        <ReportGenerator
          handleReportModal={() => this.handleReportModal()}
          reportGeneratorStatus={this.state.reportGeneratorStatus}
          setReportFromDate={(date) => this.setReportFromDate(date)}
          setReportToDate={(date) => this.setReportToDate(date)}
          reportFromDate={this.state.reportFromDate}
          reportToDate={this.state.reportToDate}
          reportedVehicle={this.state.reportedVehicle}
          generateReport={() => this.generateReport()}
          reportReadyForDownload={this.state.reportReadyForDownload}
          reportData={this.state.reportData}
          loading={this.state.loading}
        />
        <IonToast
          isOpen={this.state.errorMsg}
          onDidDismiss={() => this.setState({errorMsg: null})}
          message={this.state.errorMsg}
          duration={200}
        />
      </IonPage>
    );
  }
};

export default Reports;
